export const isImg = (fileName: string): boolean => {
  const imgExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'svg',
    'webp',
    'tiff',
    'tif',
    'heic',
  ];

  const lastDot = fileName.lastIndexOf('.');
  const fileExtension = fileName.slice(lastDot + 1).toLowerCase();
  return imgExtensions.includes(fileExtension);
};
