import { ILocalization } from 'services/api/general/ILocalization';
import { IStartMenu, ISubMenuItemV2 } from 'services/interfaces/IMenuItemV2';
import { isFavoriteMenuItem } from './isFavoriteMenuItem';

export const extractReduceSubMenu =
  (
    filter: (menu: IStartMenu) => boolean,
    getMenuName: (name: keyof ILocalization) => string,
    myFavoriteListFolder?: ISubMenuItemV2[],
    additionalFilter?: (menu: IStartMenu) => boolean
  ) =>
  (acc: ISubMenuItemV2[], cur: IStartMenu): ISubMenuItemV2[] => {
    if (filter(cur) || (additionalFilter && additionalFilter(cur))) {
      const newMenuItem = {
        ...cur,
        title: getMenuName(cur.name as keyof ILocalization),
        isFavorite: isFavoriteMenuItem(cur.name, myFavoriteListFolder),
      };
      acc.push(newMenuItem);
    }
    return acc;
  };
