import { memo, FC } from 'react';
import classNames from 'clsx';
import config from '../../../config.json';
import { ReactComponent as Burger } from '../../../img/header/burger.svg';
import { ReactComponent as VLine } from '../../../img/header/vline.svg';
import { ReactComponent as Logo } from '../../../img/logo.svg';

import { extractFullNameFromIdentity } from '../../../services/utils/misc/misc';
import Breadcrumbs from '../Breadcrumbs';
import CounterUI from 'components/layouts/CounterUI/CounterUI';
import RightHeaderElements from '../RightHeaderElements/RightHeaderElements';
import { getNumberOfNotificationsFromStore } from 'store/notification/selectors';
import {
  getActiveInstallationClientName,
  getIdentityFromStore,
} from 'store/auth/selectors';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import ColorCalculator from './utils/ColorCalculator/ColorCalculator';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { NavLink } from 'react-router-dom';
import { PATH_FRONTPAGE } from 'services/pathConstants';

type MainLayoutHeaderPropsType = { burgerHandler: () => void };

const MainLayoutHeader: FC<MainLayoutHeaderPropsType> = (props) => {
  const { burgerHandler } = props;
  const identity = useSelector(getIdentityFromStore);
  const userFullName = extractFullNameFromIdentity(identity);

  const installationClientName = useSelector(getActiveInstallationClientName);
  const firstLetter = installationClientName[0];

  const {
    external_request: externalRequestNotificationCount,
    internal_task: taskNotificationCount,
  } = useSelector(getNumberOfNotificationsFromStore);

  const hasNotifications =
    taskNotificationCount > 0 || externalRequestNotificationCount > 0;

  const colorCalculator = new ColorCalculator();

  const idInstallationClientName = `tooltip-installationClientName`;

  const uniqColorForInstallationClientLetter = colorCalculator.generateColor(
    installationClientName
  );

  return (
    <header
      className={classNames(
        styles.header,
        'd-flex flex-row justify-content-start d-print-none'
      )}
    >
      <div
        className={classNames(
          styles.left,
          'd-flex flex-row flex-nowrap align-items-center'
        )}
      >
        <button
          className={classNames(
            styles['menu-toggle'],
            'd-flex flex-column justify-content-center'
          )}
          onClick={burgerHandler}
        >
          <Burger />
          {hasNotifications && (
            <CounterUI
              count={null}
              className={styles['burger-notification-active']}
            />
          )}
        </button>
        <div>
          <NavLink
            className={classNames(
              styles.logo,
              'd-md-flex d-none flex-row flex-nowrap justify-content-center'
            )}
            to={PATH_FRONTPAGE}
          >
            <div className="d-flex">
              <Logo title={config.siteName} />
              <span
                className="ml-3"
                id={idInstallationClientName}
                style={{ color: uniqColorForInstallationClientLetter }}
              >
                {firstLetter}
              </span>
              <ToolTipWrapper target={idInstallationClientName} placement="top">
                {installationClientName}
              </ToolTipWrapper>
            </div>
          </NavLink>
        </div>
        <NavLink
          className={classNames(
            styles.logo,
            'd-sm-flex d-md-none flex-row flex-nowrap align-items-center justify-content-center'
          )}
          to={'/'}
        >
          <div className="d-sm-flex d-md-none justify-content-center ">
            <span
              className="ml-3"
              id={idInstallationClientName}
              style={{ color: uniqColorForInstallationClientLetter }}
            >
              {firstLetter}
            </span>
            <ToolTipWrapper target={idInstallationClientName} placement="top">
              {installationClientName}
            </ToolTipWrapper>
          </div>
        </NavLink>
        <VLine className={styles.vline} />
      </div>

      <Breadcrumbs />
      <RightHeaderElements userFullName={userFullName} />
    </header>
  );
};

export default memo(MainLayoutHeader);
